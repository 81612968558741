import { documentLoaded, insertScript, waitFor } from './trackingHelper'

let accepted: string[] = []
let cookieFirstLoaded: Promise<void> | undefined = undefined
type CookieFirstCallback = (services: typeof accepted) => void
let callbacks: CookieFirstCallback[] = []

const updateAccepted = (consent: Record<string, boolean>) => {
    const localAccepted = Object.entries(consent)
        .filter(([, v]) => !!v)
        .map(([k]) => k)

    accepted = localAccepted

    for (const cb of callbacks) {
        try {
            cb(localAccepted)
        } catch (e) {
            console.error(e)
        }
    }
}

const cookieFirstListener = () => {
    if (cookieFirstLoaded) {
        return cookieFirstLoaded
    }

    cookieFirstLoaded = new Promise<void>((res) => {
        let resolved = false
        const resolve = (e: any) => {
            updateAccepted(e.detail || {})

            if (resolved) {
                return
            }
            resolved = true

            res()
        }

        window.addEventListener('cf_services_consent', resolve)
        window.addEventListener('cf_services_consent_loaded', resolve)
    })

    return cookieFirstLoaded
}

const getAcceptedServices = async () => {
    await cookieFirstListener()

    return accepted
}

export const onCookieFirstServiceUpdate = (cb: CookieFirstCallback) => {
    callbacks.push(cb)
    getAcceptedServices().then(cb)
}

export const bootstrapCookieFirst = async () => {
    const src = process.env.GATSBY_COOKIE_FIRST_SRC
    if (!src) {
        return
    }

    await documentLoaded('complete')

    await waitFor(2500)

    await insertScript(src, 'cookiefirst-script')
}

export const getCookieFirst = async () => {
    await cookieFirstListener()

    return (window as any).CookieFirst
}
