module.exports = {
    title: `Kalibrierlabor Esenwein GmbH in Hochdorf im Kreis Esslingen bei Stuttgart`,
    description: `Geräte kalibrieren, akkreditiert, schnell und preiswert. Wir kalibrieren akkreditiert und rückgeführt. Ein Angebot erhalten Sie in wenigen Minuten`,
    author: `Interweber`,
    siteUrl: "https://kalibrierlabor.de",
    analytics: {
        ga: "G-84Y3YF11D2",
        tagManager: "GTM-TD6MNW7",
    },
}
