import Cookie from "js-cookie"
import siteMetadata from "../config/siteMetadata"
import { getCookieFirst } from "./tracking/cookieFirst";

const gaCode = siteMetadata.analytics.ga
const gtmCode = siteMetadata.analytics.tagManager

const accept_cookie = "helene_allow_gtag"
const gaDisable = "ga-disable-" + gaCode
let gaBootstrap = false
let active = false

export async function setGaOptOut() {
    Cookie.remove(accept_cookie)

    // @ts-ignore
    window.gtag = null;

    const cf = await getCookieFirst()
    cf.withdrawConsent()

    window.location.reload()
}

function analyticsAllowed() {
    // google opt out
    if (Cookie.get(gaDisable) === "true") {
        // @ts-ignore
        window[gaDisable] = true
        return false
    }

    return active
}

export function allowAnalytics() {
    active = true
}

function loadScript(id: string, src: string): Promise<void> {
    if (document.getElementById(id)) {
        return Promise.resolve()
    }

    const script = document.createElement("script")
    script.id = id
    script.src = src
    script.async = true

    return new Promise(res => {
        const fn = () => {
            res()

            script.removeEventListener("load", fn)
        }

        script.addEventListener("load", fn)
        document.body.append(script)
    })
}

export async function addAnalytics() {
    if (!analyticsAllowed()) {
        return
    }

    const promises: Promise<void>[] = []

    if (gaCode) {
        promises.push(
            loadScript(
                "gatsby-plugin-google-gtag",
                `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
            )
        )
    }

    if (gtmCode) {
        promises.push(
            loadScript(
                "gatsby-plugin-google-gtm",
                `https://www.googletagmanager.com/gtm/js?id=${gtmCode}`
            )
        )
    }

    const wait = Promise.all(promises)

    if (gaBootstrap) {
        await wait

        return
    }

    gaBootstrap = true

    gtag('js', new Date());
    gtag('config', gaCode, {
        anonymize_ip: true
    });

    gtag({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    })

    sendPageView(window.location)

    await wait
}

export const gtag = (...args: any[]) => {
    if (window.gtag) {
        window.gtag(...args)

        return
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(...args);
}

export const ga_event = (
    eventName: Gtag.EventNames | string,
    eventParams?: Gtag.EventParams | Gtag.CustomParams
) => {
    if (gaBootstrap) {
        return
    }

    gtag("event", eventName, eventParams)
}

export const sendPageView = (location: Location) => {
    if (!analyticsAllowed()) {
        return
    }

    const run = () => {
        const pagePath = location
            ? location.pathname + location.search + location.hash
            : undefined

        ga_event("page_view", {
            page_path: pagePath,
        })
    }

    if ("requestAnimationFrame" in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(run)
        })
    } else {
        // simulate 2 rAF calls
        setTimeout(run, 32)
    }
}
