import "./src/styles/tailwind.css"

import { addAnalytics, allowAnalytics, sendPageView } from "./src/handle_ga";
import { bootstrapCookieFirst, onCookieFirstServiceUpdate } from "./src/tracking/cookieFirst";

export const onClientEntry = async () => {
    onCookieFirstServiceUpdate((accepted) => {
        if (accepted.includes('google_analytics')) {
            allowAnalytics()
            addAnalytics()
        }
    })
    await bootstrapCookieFirst()
}

export const onRouteUpdate = ({ location }) => {
    sendPageView(location)
}
